<script>
import Layout from "../../layouts/main";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Breadcrumb from "@/components/breadcrumb";
import * as moment from "moment";
export const CHAIPAY_APP_URL = process.env.VUE_APP_CHAIPAY_FRONTEND_URL;

import {
  FETCH_RECONCILIATIONS,
  FETCH_CHANNEL_LIST
} from "@/state/actions.type";
import {
} from "@/state/mutations.type";
import Multiselect from 'vue-multiselect';
import Filters from '../../../components/filters.vue';
// import SearchBy from '../../../components/search-by.vue';
import PerPage from '../../../components/per-page.vue';
import cloneDeep from 'lodash.clonedeep';

export default {
  components: {
    Layout,
    Multiselect,
    Filters,
    // SearchBy,
    Breadcrumb,
    PerPage
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      is_processing: false,
      selectedDates: [null, null],
      filters: {
        data_source: [],
        status: [],
        recon_id: ""
      },
    //   searchBy: {
    //     key: "recon_id",
    //     value: ""
    //   },
    //   filterOptions: ['Recon ID'],
    //   currentSelectedFilter: "Recon ID",
      reconList: [],
      totalElements: 0,
      isFetching: false,
      fields: [
        { key: "created_at", sortable: true, label: "Date" },
        { key: "data_source_a_name", sortable: true, label: "Data Source" },
        { key: "data_source_b_name", sortable: true, label: "Corresponding Data Source" },
        { key: "status", sortable: true, label: "Recon Status" },
        { key: "show_details", label: '', stickyColumn: true }
      ],
      showDetails: false,
      reconDetails: null
    };
  },

  filters: {
    date(value) {
      return moment(value).format("DD MMM YYYY");
    },
    time(value) {
      return 'at ' + moment(value).format("hh:mm A");
    },
    datetime(value) {
      return moment(value).format("DD MMM YYYY, hh:mm A");
    }
  },

  created() {
    this.$store.dispatch(`reconciliation/${FETCH_CHANNEL_LIST}`);
  },

  computed: {
    paymentChannelList() {
      return this.$store.state.reconciliation.paymentChannelList.list || [];
    },

    items() {
      return [
        {
          text: 'Upload Files',
          to: { name: 'Upload Files' }
        },
        {
          text: 'View Reconciliation',
          active: true
        }
      ];
    },
  },

  methods: {
    fetchTransactions() {
      let filters = cloneDeep(this.filters);

      if(filters.data_source && filters.data_source.length > 0) {
        filters.data_source = filters.data_source.map(channel => channel.source_name);
      }

    //   if(this.searchBy.key === 'Recon ID' && this.searchBy.value) {
    //     filters.recon_id = this.searchBy.value;
    //   }

      let payload = {
        page: this.currentPage,
        pagesize: this.perPage,
        filters: filters,
      };

      if(this.selectedDates[0]) {
        let fromDate = new Date(this.selectedDates[0]);
        fromDate.setHours(0, 0, 0);
        payload.from_date = moment(fromDate).toJSON();
      }

      if(this.selectedDates[1]) {
        let toDate = new Date(this.selectedDates[1]);
        toDate.setHours(23, 59, 59);
        payload.to_date = moment(toDate).toJSON();
      }

      this.isFetching = true;
      this.$store.dispatch(`reconciliation/${FETCH_RECONCILIATIONS}`, payload).then((response) => {
        console.log(response);
        this.reconList = response.data.recon_list;
        this.totalElements = response.data.total;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => this.isFetching = false);
    },
    resetAdvanceFilters() {
      this.filters = {
        data_source: [],
        status: [],
        recon_id: this.filters.recon_id
      },
      this.fetchTransactions();
    },
    dateChanged(value) {
      this.selectedDates[0] = value[0];
      this.selectedDates[1] = value[1];
      this.fetchTransactions();
    },
    toggleFilter() {
      this.$root.$emit('bv::toggle::collapse', 'recon-filter-collapse');
    },
    // searchByChanged() {
    //   clearTimeout(this.timeout);
    //   this.timeout = setTimeout(this.fetchTransactions, 700);
    // }
    hideDetails() {
      this.showDetails = false;
    },
    showReconDetails(item) {
      this.reconDetails = item;
      this.showDetails = true;
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout :loading="is_processing">
    <breadcrumb :items="items" :goBackTo="{ name: 'Upload Files' }" />
    <filters class="mb-1 mt-4" dateFormat="DD MMM, YYYY" @date-change="dateChanged" @toggle-filter="toggleFilter" />

    <b-collapse id="recon-filter-collapse" @hidden="resetAdvanceFilters" @show="resetAdvanceFilters" style="padding: 25px;" class="common-border bg-white">
      <div class="d-flex" style="gap: 24px;">
        <div class="flex-fill">
          <label class="mb-2" for="data_source">Data Source</label>
          <multiselect
            name="data_source"
            v-model="filters.data_source"
            :options="paymentChannelList"
            placeholder="Select Data Source"
            track-by="source_name"
            label="display_name"
            :searchable="false"
            multiple
          ></multiselect>
        </div>
        <div style="width: 228px;">
          <label class="mb-2" for="status">{{ $t('common.status') }}</label>
          <multiselect
            name="status"
            v-model="filters.status"
            :options="['Created', 'Successful', 'Failed']"
            :placeholder="$t('common.select_status')"
            :searchable="false"
            multiple
          ></multiselect>
        </div>
      </div>
      <div class="mt-4 d-flex justify-content-end">
        <b-button variant="white" class="mr-2 bg-white text-dark border-dark cancel" @click="toggleFilter">{{ $t('button.cancel') }}</b-button>
        <b-button variant="primary" class="btn-padding" @click="fetchTransactions" >{{ $t('button.apply') }}</b-button>
      </div>
    </b-collapse>

    <!-- <search-by :options="filterOptions" v-model="searchBy" @search-by-clicked="searchByChanged" class="my-3" /> -->

    <b-table
      class="table-custom mt-3"
      :items="reconList"
      :fields="fields"
      responsive="sm"
      sticky-header="50vh"
      show-empty
      :empty-text="$t('common.no-records')"
      :busy="isFetching"
      hover
    >
      <template v-slot:head()="data">
          {{ $t(data.label) }}
      </template>
      <template v-slot:cell(created_at)="row">
        <p class="table-date">{{ row.value | date }}</p>
        <p class="table-time">{{ row.value | time }}</p>
      </template>

      <template #cell(status)="{value}">
            <div
                class="badge custom-status"
                :class="{
                    'badge-soft-danger': value === 'Failed',
                    'badge-soft-success': value === 'Successful',
                    'badge-soft-info': value === 'Processing'
                }"
            >{{ value }}</div>
      </template>

      <template #cell(show_details)="{item}">
            <div class="d-flex justify-content-end" style="gap: 10px;">
                <b-btn size="sm" @click="$router.push({name: 'Reconciliation Dashboard', query: {source_a: item.data_source_a_id, source_b: item.data_source_b_id}})" :disabled="item.status !== 'Successful'" :style="item.status !== 'Successful' ? `color: #FFF; border: rgba(0, 0, 0, 0.30); background: rgba(0, 0, 0, 0.30);` : ''" variant="primary" class="font-12px" style="line-height: 20px; border-radius: var(--8, 8px);">Check Result</b-btn>
                <b-btn size="sm" @click="showReconDetails(item)" variant="white" style="border-radius: var(--8, 8px); background: rgba(220, 218, 213, 0.30);">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path d="M5.19727 11.6199L9.0006 7.81655C9.44977 7.36738 9.44977 6.63238 9.0006 6.18322L5.19727 2.37988" stroke="black" stroke-opacity="0.3" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </b-btn>
            </div>
        </template>
    </b-table>

    <div class="d-flex justify-content-between">
        <per-page
            v-model="perPage"
            :currentPage="currentPage"
            :totalRows="totalElements"
            :tableRowsCount="reconList.length"
            @input="fetchTransactions"
        />
        <b-pagination
            class="my-auto"
            v-model="currentPage"
            :current-page="currentPage"
            :total-rows="totalElements"
            :per-page="perPage"
            @input="fetchTransactions"
        ></b-pagination>
    </div>

     <!-- Right Sidebar -->
    <div class="right-bar"
      style=""
      :class="{
        'active': showDetails
    }">
        <div class="pt-4 px-4 ml-2 d-flex justify-content-between">
            <div class="pt-3 mt-1">
                <p style="font-size: 18px; font-weight: 400; line-height: 24px;" class="mb-0">
                    Reconciliation Details
                </p>
                <p class="section-text">Check details of the reconciliation here.</p>
            </div>
            <i class="mdi mdi-close noti-icon cursor-pointer mb-auto" style="position: relative;z-index: 1; font-size: 20px;" @click="hideDetails"></i>
        </div>

        <hr class="my-4" style="background: rgba(220, 218, 213, 0.30); border: none; height: 1px;">

        <div v-if="reconDetails">
          <div class="px-4 ml-2">
            <p class="custom-label mb-1">Reconciliation Start Date</p>
            <p class="custom-label text-black mb-3">{{ reconDetails.created_at | datetime }}</p>
  
            <p class="custom-label mb-1">Reconciliation ID</p>
            <p class="custom-label text-black mb-3">{{ reconDetails.recon_id }}</p>
  
            <p class="custom-label mb-1">File Data Source (A)</p>
            <p class="custom-label text-black mb-3">{{ reconDetails.data_source_a_name }}</p>
  
            <p class="custom-label mb-1">Corresponding Data Source (B)</p>
            <p class="custom-label text-black mb-3">{{ reconDetails.data_source_b_name }}</p>
  
            <p class="custom-label mb-1">Status</p>
            <div
              class="badge custom-status"
              :class="{
                'badge-soft-danger': reconDetails.status === 'Failed',
                'badge-soft-success': reconDetails.status === 'Successful',
                'badge-soft-info': reconDetails.status === 'Processing'
              }"
            >{{ reconDetails.status }}</div>
          </div>
  
          <hr class="my-4" style="background: rgba(220, 218, 213, 0.30); border: none; height: 1px;" v-if="reconDetails.status !== 'Processing'">
  
          <div class="px-4 ml-2" v-if="reconDetails.status !== 'Processing'">
            <p class="custom-label mb-1" v-if="reconDetails.status === 'Failed'">Failure Reason</p>
            <p class="custom-label text-chai mb-1" v-else>Reconciliation successful!</p>
            <p class="custom-label text-black mb-3">{{ reconDetails.message }}</p>
            <b-btn
              size="sm"
              @click="$router.push({name: 'Reconciliation Dashboard', query: {source_a: reconDetails.data_source_a_id, source_b: reconDetails.data_source_b_id}})"
              v-if="reconDetails.status === 'Successful'"
              variant="primary"
              class="font-14px py-2 px-2 border-0"
              style="line-height: 20px !important; border-radius: 12px;"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" class="ml-1 mr-2">
                <path d="M12.0243 4.94141L17.0827 9.99974L12.0243 15.0581M2.91602 9.99974H16.941" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <span class="mr-1">
                Check Result
              </span>
            </b-btn>
          </div>
        </div>
      <!-- end slimscroll-menu-->
    </div>
      <!-- /Right-bar -->
      <!-- Right bar overlay-->
    <div @click="hideDetails" class="rightbar-overlay"
      :class="{
        'active': showDetails
      }">
    </div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep label {
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
}
.custom-label {
  color: rgba(0, 0, 0, 0.30);
  font-size: 14px;
  font-style: normal;
  font-weight: 350;
  line-height: 20px;
}
.text-black{
  color: #000 !important;
}
::v-deep .xmx-datepicker {
  width: 245px;
}
.right-bar{
  width: 448px;
  right: -448px;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
  z-index: 9997;
}
@media (max-width: 700px) {
  .right-bar{
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
</style>